<template>
    <loading
      :active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>
 
    <div class="page-wrapper">
      <div class="container-fluid">
        <div class="row page-titles">
          <div class="col-md-5 align-self-center"></div>
          <div class="col-md-7 align-self-center text-end">
            <div class="d-flex justify-content-end align-items-center">
              <ol class="breadcrumb justify-content-end">
                <li class="fw-bold h3">
                  <span>Moyens de paiements</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row container-fluid">
        <div class="conteneur_modify" v-show="showDetailPayment">
            <div class="content position-relative">
              <div
                class="ecriteau text-start d-flex justify-content-between align-items-center"
              >
                <h4>Détail</h4>
                <i
                  class="bi bi-x-circle h1"
                  @click.prevent="closeshowDetailPayment"
                ></i>
              </div>
              <div class="text-start px-3">
                <div class="submit-pages text-start">
                  <div class="row container">
                    <div class="form-group col-md-12 col-sm-12">
                     
                     
                      <div class="col-lg-12 col-md-12">
                        <form @submit.prevent="modifyPayment">
                          <div class="submit-pages">
                            <!-- Basic Information -->
                            <div class="form-submit">
                            
                              <div class="submit-section">
                                <div class="row">
                                  <div class="form-group col-md-6">
                                    <label>Libellé</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="libelle"
                                      required
                                    />
                                  </div>
                                  <div class="form-group col-md-6">
                                    <label>Montant maximal</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="montant_maximal"
                                      required
                                    />
                                  </div>
              
                                  <div>
                                    <button
                                     class="btn bg-pen mx-2 bg-primary text-light"
                                      type="submit"
                                    >
                                      Enregistrer
                                      <span class="spinner-border spinner-border-sm ms-2" v-show="preloaderSpinner"  role="status" aria-hidden="true"></span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>




        <div class="col-md-12">
          <div class="table-responsive">
            <table id="MyTableData" class="table" v-if="list_payment">
              <thead>
                <tr>
                  
                  <th class="bg-light">libellé</th>
                  <th class="bg-light">Montant maximal</th>
               
                  <th class="bg-light text-right">Détails</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in list_payment" :key="index">
                
                  <td>
                    {{ `${item.libelle}` }}
                  </td>
                
                  <td>
                    {{ this.moneyFormat.format(item.max) }} Fcfa
                  </td>
                 
               
                  <td class="text-right">
                    <div
                      class="dropdown dropdown-action d-flex justify-content-center"
                    >
                      <span
                      @click="modifyPaiement(item.id)"
                        class="btn bg-pen boutons mx-2 bg-primary text-light"
                      >
                        <i class="bi bi-pencil-fill"></i>
                  </span>
                      <button
                        @click="show(item.id)"
                        title="Supprimer"
                        class="btn btn-lg boutons bg-danger text-light"
                      >
                        <i class="bi bi-trash3-fill"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
      <div class="delete_personne" v-show="showMsg">
        <div class="delete">
          <span class="fw-bold">vous-voulez vraiment supprimer ?</span>
          <button
            class="bg bg-pen text-light my-3 border-0 rounded p-2 fw-bold"
            @click="Supprime_mode_Payment(this.id_delete)"
          >
            Supprimer
          </button>
          <button
            class="bg bg-dark text-light border-0 rounded p-2 fw-bold"
            @click="fermer"
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
    <Footer class="my_footer" v-if="list_payment !== null"></Footer>
  </template>
  <script>
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  // import Header from "@/components/header";
  // import Menu from "@/components/menu";
  // import Footer from "@/components/footer";
  import Swal from "sweetalert2";
  import { lien } from "/src/assets/api.js";
  import $ from "jquery";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import axios from "axios";
  export default {
    name: "Packagesnotpublish",
    data() {
      return {
        list_payment:[],
        isLoading: false,
        showMsg: false,
        id_delete: null,
        idPayment:"",
        libelle: "",
        montant_maximal: "",
        showDetailPayment:false,
        preloaderSpinner:false,
        moneyFormat: new Intl.NumberFormat("de-DE"),
      };
    },
    components: {
      // Header,
      // Menu,
      // Footer,
      Loading,
    },
    methods: {
        async modifyPayment() {
   
    let data = {
        libelle: this.libelle,
        max: this.montant_maximal,
      }
      this.preloaderSpinner = true
      await axios
        .put(lien + "/api/moyens/"+this.idPayment, data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          //console.log("Reponse",response.data);

          if (response.data.status === true) {
            //console.log("Reponse", response.data);
            Swal.fire({
              icon: "success",
              title: "le moyen de paiement a été modifié.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.list_payment.forEach((item) => {
              if (item.id == this.idPayment) {
                item.libelle = this.libelle;
                item.max = this.montant_maximal;
              }
            });
            this.showDetailPayment = !this.showDetailPayment;
            this.preloaderSpinner = false
          }
          if(response.data.status === false){
            //console.log("ReponseModify",response.data);
            Swal.fire({
              icon: "error",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
            this.preloaderSpinner = false
          }
       
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Problème avec le serveur....",
            showConfirmButton: false,
            timer: 2500,
          });
          this.preloaderSpinner = false
        });
    },
    closeshowDetailPayment(){
        this.idPayment = ""
        this.showDetailPayment = !this.showDetailPayment;
        },
        modifyPaiement(id){
            //console.log(id)
            this.idPayment = id
            this.list_payment.forEach(item=>{
                if(item.id == id){
                    this.libelle = item.libelle
                    this.montant_maximal = item.max
                }
            })
            this.showDetailPayment = !this.showDetailPayment;
        },
      get_list_payment() {
        this.isLoading = true;
        axios
          .get(lien + "/api/moyens", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
  
          .then((res) => {
            //console.log("this.list_payment", res.data.data);
            this.list_payment = res.data.data;
            //console.log("LIST", this.list_payment);
            this.isLoading = false;
            setTimeout(function () {
              $("#MyTableData").DataTable({
                pagingType: "full_numbers",
                pageLength: 10,
                processing: true,
                order: [],
                language: {
                  décimal: "",
                  emptyTable: "Aucune donnée disponible dans le tableau",
                  infoEmpty: "Showing 0 to 0 of 0 entries",
                  info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                  infoFiltered: "(filtré à partir de _MAX_ entrées totales)",
                  infoPostFix: "",
                  thousands: ",",
                  lengthMenu: "Afficher les entrées du _MENU_",
                  loadingRecords: "Loading...",
                  processing: "Processing...",
                  search: "Chercher :",
                  stateSave: true,
                  zeroRecords: "Aucun enregistrement correspondant trouvé",
                  paginate: {
                    first: "Premier",
                    last: "Dernier",
                    next: "Suivant",
                    previous: "Précédent",
                  },
                  aria: {
                    sortAscending: ": activate to sort column ascending",
                    sortDescending: ": activate to sort column descending",
                  },
                },
              });
            }, 10);
          });
      },
      Supprime_mode_Payment(id) {
        axios
          .delete(lien + "/api/moyens/" + id)
          .then((reponse) => {
            //console.log("DELETEARRAY", reponse);
            if (reponse.data.status === true) {
              Swal.fire({
                text: "moyen de paiement supprimé",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
              });
              const itemAtdelete = this.list_payment.findIndex(
              (item) => item.id == this.idCategorie
            );
            this.list_payment.splice(itemAtdelete, 1);
            }
            if (reponse.data.status === false) {
              Swal.fire({
                text:reponse.data.message,
                icon: "info",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.showMsg = !this.showMsg;
          })
          .catch((error) => {
            if (error) {
              Swal.fire({
                text: "Contactez le service informatique",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
              });
            }
          });
      },
      show(id) {
        this.showMsg = !this.showMsg;
        this.id_delete = id;
        //console.log("ID A DELETE", this.id_delete);
      },
      fermer() {
        this.showMsg = !this.showMsg;
        this.id_delete = null;
        //console.log("ID A DELETE", this.id_delete);
      },
    },
    created() {
      this.get_list_payment();
    },
  };
  </script>
  <style scoped>
  .delete_personne {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.099);
    top: 0;
    left: 0;
    display: flex;
    place-items: center;
    justify-content: center;
  }
  .delete {
    padding: 3em;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.285);
  }
  .table {
    border: thin solid rgba(139, 139, 139, 0.63) !important;
  }
  th,
  td {
    border: thin solid rgba(141, 140, 140, 0.692) !important;
  }
  .bg-pen {
    background: rgb(231, 202, 15) !important;
    border: 1px solid black !important;
  }
  .bg-danger {
    background: crimson !important;
    border: 1px solid black !important;
  }
  .my_footer {
    position: absolute;
    width: 100%;
    bottom: -9em;
    margin-left: 0 !important;
  }
  .boutons {
    width: 28px !important;
    height: 28px !important;
    display: flex;
    place-items: center;
    justify-content: center;
  }
  input,
  select {
    border: 1px solid black !important;
  }
  .form-group {
    text-align: left !important;
  }
  .conteneur_modify {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.255);
    display: flex;
    justify-content: center;
    place-items: center;
  }
  .conteneur_modify .content {
    width: 80%;
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    border-radius: 1em;
    box-shadow: 1px 1px 1px 6px rgba(0, 0, 0, 0.128);
  }
  .submit-pages {
    padding: 1em 3em;
  }
  .ecriteau {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1em;
    z-index:9999;
    background-color: white;
    box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.129);
  }
  </style>
  